
// -----------------------------------------------------------------------------
// Menu
// -----------------------------------------------------------------------------

const toggleMenu = document.querySelector('.js-menu-button');

toggleMenu.addEventListener('click', function () {
  const menu = document.querySelector('.js-menu-handheld');
  const html = document.documentElement;
  const body = document.getElementsByTagName('body')[0];
  html.classList.toggle('js-nav-mobile-open')
  body.classList.toggle('js-nav-mobile-open')
  const open = JSON.parse(toggleMenu.getAttribute('aria-expanded'));
  toggleMenu.setAttribute('aria-expanded', !open);
  menu.hidden = !menu.hidden;
});


// -----------------------------------------------------------------------------
// Slider
// -----------------------------------------------------------------------------

const slider = document.querySelector('.slider');


if (slider) {
  const sliderNav = document.querySelector('.slider-nav');

  const slider = tns({
    container: slider,
    items: 1,
    slideBy: 'page',
    speed: 600,
    navContainer: sliderNav,
    controls: false,
    autoplayButtonOutput: false,
    autoplay: true
  });
}